import React, { createElement } from "react"
import { graphql } from "gatsby"

import Seo from "../components/seo"
import Layout from "../components/layout"
import { FadeInTop } from "../components/animations"
import BlockContent from "../components/BlockContent"

const Legal = ({ data }) => {
  const { title, content } = data.sanityLegal

  return (
    <Layout>
      <Seo title={title} />
      <section className="c-container-full pt-28 sm:pt-80 pb-24">
        <div className="flex">
          <div className="w-0 sm:w-1/12"></div>
          <div className="sm:w-7/12">
            <FadeInTop>
              <h1 className="font-headings text-5xl sm:text-8xl lg:text-8xl leading-tight sm:leading-[1.3] w-max-620px mb-12 sm:mb-16">
                {title}
              </h1>
            </FadeInTop>
          </div>
        </div>
        <div className="flex">
          <div className="sm:w-2/12"></div>
          <div className="sm:w-8/12 c-rich-text">
            <BlockContent value={content} />
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Legal

export const query = graphql`
  query LegalPage($title: String!) {
    sanityLegal(title: { eq: $title }) {
      title
      content: _rawContent
    }
  }
`
